.imgNavBar {
    max-width: 100%;
    height: 70px;
}

.NavFirstItem {
    margin-left: 10px;
}

.navlink-custom {
    margin-inline: 10px;
    font-size: 20px;
    font-family: 'GobCL_BoldLight';
}

.Smart-color {
    color: var(--darkGreen-INIA);
    margin-left: 10px;
    font-size: 58px;
    font-family: 'GobCL_BoldLight';
}

.Field-color {
    color: var(--lightGreen-INIA);
    font-size: 58px;
    font-family: 'GobCL_BoldLight';
}

/* Media Query for Mobile */
@media (max-width: 768px) {
    .imgNavBar {
        display: none;
    }

    .Smart-color {
        color: var(--darkGreen-INIA);
        margin-left: 10px;
        font-size: 43px;
        font-family: 'GobCL_BoldLight';
    }
    
    .Field-color {
        color: var(--lightGreen-INIA);
        font-size: 43px;
        font-family: 'GobCL_BoldLight';
    }
}
