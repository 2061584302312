.homemission-leftcolumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.homemission-title {
    margin-top: 5%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 1rem;
}

.homemission-paragraph {
    font-family: 'GobCL_LightRegular';
    text-align: justify;
    font-size: 20px;
    padding: 30px;
}

.homemissioncomponent{
    margin-top: 10px;
    margin-bottom: 10px;
}

.homemission-item{
    height: 400px;
}

.homemission-carousel{
    margin-top: 5%;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
}

/* Mobile Design */
@media (max-width: 768px) {

    .homemission-item{
        height: 230px;
    }

    .homemission-title {
        margin-top: 3%;
        font-family: 'GobCL_BoldWeight';
        font-size: 25px;
        margin-bottom: 1rem;
    }
    
    .homemission-paragraph {
        font-family: 'GobCL_LightRegular';
        text-align: justify;
        font-size: 16px;
        padding: 10px;
    }
}