/* Ejemplo de estilos adicionales */
.Capabilities-cardtitle {
    font-family: 'GobCL_BoldLight';
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.Capabilities-cardtext {
    font-family: 'GobCL_LightWeight';
    text-align: center;
    font-size: 20px;
}

.Capabilities-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
}


.Capabilities-button{
    margin-left: 40%;
}

.Capabilities-cardimg{
    height: 300px;
}

.Capabilitiescomponent{
    margin-top: 60px;
}

.Capabilities-cardtheme{
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
    height: 530px;
}