@font-face {
    font-family: 'GobCL_LightRegular';
    src: url('./pages/assets/font/gobCL_LightRegular.otf') format('opentype');
}
@font-face {
    font-family: 'GobCL_LightWeight';
    src: url('./pages/assets/font/gobCL_LightWeight.otf') format('opentype');
}
@font-face {
    font-family: 'GobCL_BoldLight';
    src: url('./pages/assets/font/gobCL_BoldLight.otf') format('opentype');
}
@font-face {
    font-family: 'GobCL_BoldWeight';
    src: url('./pages/assets/font/gobCL_BoldWeight.otf') format('opentype');
}
