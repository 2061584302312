.usmission-left-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.usmission-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.usmission-paragraph {
    font-family: 'GobCL_LightWeight';
    text-align: justify;
    font-size: 25px;
}

.usmission-img{
    max-width: 100%;
    height: 90%;
}

/* Mobile Design */
@media (max-width: 768px) {
    .usmission-left-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    
    .usmission-title {
        font-family: 'GobCL_BoldWeight';
        font-size: 20px;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    
    .usmission-paragraph {
        font-family: 'GobCL_LightWeight';
        text-align: justify;
        font-size: 16px;
    }
    
    .usmission-img{
        max-width: 100%;
        height: 90%;
        align-items: center;
    }
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      }
}