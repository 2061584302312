/* src/components/Home/About.css */
.about-container {
    position: relative;
    width: 100%;
    height: 500px; /* Altura de la imagen */
    background-size: cover;
    background-position: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Sombreado interior */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .titulo-about{
    font-family: 'GobCL_BoldWeight';
  }
  
  .overlay h2 {
    font-family: 'GobCL_BoldWeight';
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  .overlay p {
    font-size: 20px;
    font-family: 'GobCL_LightWeight';
    text-align: justify;
  }
  
  .color-button{
    background-color: rgb(147, 185, 59);
  }


  @media (max-width: 768px) {
    .about-container {
      position: relative;
      width: 100%;
      height: 740px; /* Altura de la imagen */
      background-size: cover;
      background-position: center;
    }
    .overlay h2 {
      font-family: 'GobCL_BoldWeight';
      font-size: 25px;
      margin-bottom: 20px;
    }
    
    .overlay p {
      text-align: justify;
      font-size: 16px;
      font-family: 'GobCL_LightWeight';
    }
}