.information-capture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.flowchart-title {
  margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.flowchart-table {
  width: 100%;
  text-align: center;
}

.flowchart-table th, .flowchart-table td {
  vertical-align: middle;
}

.imgFlowchart {
  height: 250px;
  width: 230px;
  object-fit: cover;
}

.titlesFlowTable{
  font-size: 20px;
}

.subtitlesFlowTable{
  font-size: 18px;
}

@media (max-width: 768px) {
  .information-capture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .flowchart-title {
    margin-top: 0%;
      font-family: 'GobCL_BoldWeight';
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
  }
  
  .flowchart-table {
    width: 100%;
    text-align: center;
  }
  
  .flowchart-table th, .flowchart-table td {
    vertical-align: middle;
  }
  
  .imgFlowchart {
    height: 70px;
    width: 90px;
    object-fit: cover;
  }
  
  .titlesFlowTable{
    font-size: 10px;
  }
  
  .subtitlesFlowTable{
    font-size: 12px;
  }
  td{
    font-size: 10px;
  }
}
