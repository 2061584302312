/* Ejemplo de estilos adicionales */
.homeproducts-cardtitle {
    font-family: 'GobCL_BoldLight';
    text-align: center;
    font-size: 25px;
}

.homeproducts-cardtext {
    font-family: 'GobCL_LightWeight';
    text-align: center;
    font-size: 20px;
}

.homeproducts-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 1rem;
}


.homeproducts-button{
    margin-left: 40%;
}

.homeproducts-cardimg{
    height: 230px;
    
}

.homeproductscomponent{
    margin-top: 60px;
}

.homeproducts-cardtheme{
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
    height: 470px;
}

/* Mobile Design */
@media (max-width: 768px) {

    .homeproducts-cardtext {
        font-family: 'GobCL_LightWeight';
        text-align: center;
        font-size: 16px;
    }
    
    .homeproducts-title {
        font-family: 'GobCL_BoldWeight';
        font-size: 25px;
        margin-bottom: 1rem;
    }

    .homeproducts-cardtitle {
        font-family: 'GobCL_BoldLight';
        text-align: center;
        font-size: 20px;
    }

    .homeproducts-cardtheme{
        box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
        height: 400px;
    }
}