/* Estructura general */
.intro-container {
    position: relative;
    height: 700px;
}

.intro-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.intro-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el recuadro */
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.443); /* Color de fondo gris */
    padding: 2rem;
    border-radius: 15px;
    max-width: 80%;
    width: 100%;
    height: 230px;
}

.intro-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: white;
}

.intro-text {
    font-family: 'GobCL_LightWeight';
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: white;
}

.intro-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.intro-button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    color: white;
    border-radius: 5px;
    background-color: var(--lightGreen-INIA);
    border: none;
    cursor: pointer;
    font-family: 'GobCL_LightWeight';
}

/* Estilos específicos para móvil */
@media (max-width: 768px) {
    .intro-content {
        padding: 1rem;
        width: 90%;
        height: 400px;
    }

    .intro-title {
        font-size: 1.75rem;
    }

    .intro-text {
        font-size: 1rem;
    }

    .intro-buttons {
        flex-direction: column;
    }

    .intro-button {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}
