.smartfieldintro-container {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.smartfieldintro-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.smartfieldintro-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.smartfieldintro-card {
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    text-align: center;
}

.smartfieldintro-title {
    font-family: 'GobCL_BoldLight';
    
    text-shadow: 2px 0 #1C8C4D, -2px 0 #1C8C4D, 0 2px #1C8C4D, 0 -2px #1C8C4D,
             1px 1px #1C8C4D, -1px -1px #1C8C4D, 1px -1px #1C8C4D, -1px 1px #1C8C4D;

    color: white;
    font-size: 70px;
    margin-bottom: 30px;
}

.buttonSmartfieldintro{
    background-color: var(--lightGreen-INIA);
    font-size: 20px;
    margin-right: 20px;
    font-family: 'GobCL_LightWeight';
}    

@media (max-width: 768px) {
    .smartfieldintro-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 500px;
    }

    .smartfieldintro-card {
        margin: 20px auto; /* Asegura que el card esté centrado horizontalmente */
        text-align: center; /* Centra el texto dentro del card */
        padding: 10px; /* Ajusta el padding si es necesario */
        max-width: 100%; /* Limita el ancho del card para evitar que se extienda demasiado en pantallas pequeñas */
    }

    .smartfieldintro-title {
        font-size: 25px;
        margin-bottom: 20px;
    }
    
    .smartfieldintro-paragraph {
        font-size: 18px;
    }

    .smartfieldintro-intro {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 700px;
        background-color: #00000078; /* Placeholder color */
        position: relative;
    }
}
