/* Ejemplo de estilos adicionales */
.TrainingAvaible-cardtitle {
    font-family: 'GobCL_BoldLight';
    text-align: center;
    font-size: 25px;
}

.TrainingAvaible-cardtext {
    font-family: 'GobCL_LightWeight';
    text-align: center;
    font-size: 20px;
}

.TrainingAvaible-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 1rem;
}


.TrainingAvaible-button{
    margin-left: 40%;
}

.TrainingAvaible-cardimg{
    height: 300px;
}

.TrainingAvaiblecomponent{
    margin-top: 60px;
}

.TrainingAvaible-cardtheme{
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
    height: 820px;
}