.homeenterpriseenterprise-container {
    text-align: center;
    margin: 2rem auto;
    max-width: 80%;
}

.homeenterpriseenterprise-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 1rem;
}

.homeenterprisecarousel-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.homeenterprisecarousel-image {
    max-width: calc(100% / 6); /* Adjust to show 6 images per row */
    padding: 0.5rem;
}

/* Mobile Design */
@media (max-width: 768px) {
    .homeenterprisecarousel-image {
        max-width: calc(100% / 3); /* Adjust to show 3 images per row */
    }

    .homeenterpriseenterprise-title {
        font-family: 'GobCL_BoldWeight';
        font-size: 25px;
        margin-bottom: 1rem;
    }
}
