.Corfo-container {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.Corfo-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.Corfo-overlay {
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Corfo-card {
    background-color: rgba(128, 128, 128, 0.495);
    padding: 20px;
    width: 30%;
    border-radius: 10px;
    text-align: center;
}

.Corfo-title {
    margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.Corfo-paragraph {
    font-family: 'GobCL_LightWeight';
    color: rgb(0, 0, 0);
    font-size: 30px;
    text-align: justify;
    margin-top: 5%;
}

.Corfo-img{
    width: 100%;
    margin: auto;
    display: block;
}

.Corfo-left-column{
    margin-top: 4%;
}