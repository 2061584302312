.Ecosystem-container {
    margin-top: 2rem;
}

.Ecosystem-title {
    margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.Ecosystem-card {
    margin-bottom: 5%;
    background-color: #f8f9fa; /* Optional: Change background color if needed */
    -webkit-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    -moz-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
}

.usEcosystem-img{
    height: auto;
}

.usEcosystem-arrow{
    margin-bottom: 50%;
    margin-top: 5%;
}

.Ecosystem-card-text{
    font-family: 'GobCL_LightWeight';
    font-size: 18px;
}

/* Mobile Design */
@media (max-width: 768px) {
    .Ecosystem-container {
        margin-top: 2rem;
    }
    
    .Ecosystem-title {
        margin-top: 6%;
        font-family: 'GobCL_BoldWeight';
        font-size: 20px;
        margin-bottom: 40px;
        text-align: center;
    }
    
    .Ecosystem-card {
        margin-bottom: 5%;
        width: 100%;
        background-color: #f8f9fa; /* Optional: Change background color if needed */
        -webkit-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
        -moz-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
        box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    }
    
    .usEcosystem-img{
        height: auto;
    }
    
    .usEcosystem-arrow{
        margin-bottom: 420%;
        margin-top: 5%;
    }
    
    .Ecosystem-cardBody{
        width: 100%;
    }

    .Ecosystem-card-text{
        font-family: 'GobCL_LightWeight';
        font-size: 16px;
    }
}