.ProcessValidation-container {
    margin-top: 2rem;
}

.ProcessValidation-title {
    margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.ProcessValidation-card {
    margin-bottom: 6%;
    background-color: #f8f9fa; /* Optional: Change background color if needed */
    -webkit-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    -moz-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
}

.SmartfieldProcessValidation-img{
    height: auto;
}

.SmartfieldProcessValidation-arrow{
    margin-bottom: 50%;
    margin-top: 5%;
}

.ProcessValidation-card-text{
    font-family: 'GobCL_LightWeight';
    font-size: 18px;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
    .ProcessValidation-container {
        margin-top: 2rem;
    }
    
    .ProcessValidation-title {
        margin-top: 6%;
        font-family: 'GobCL_BoldWeight';
        font-size: 20px;
        margin-bottom: 1rem;
        text-align: center;
    }
    
    .ProcessValidation-card {
        margin-bottom: 5%;
        background-color: #f8f9fa; /* Optional: Change background color if needed */
        -webkit-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
        -moz-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
        box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    }
    
    .SmartfieldProcessValidation-img{
        height: auto;
    }
    
    .SmartfieldProcessValidation-arrow{
        margin-bottom: 370%;
        margin-top: 5%;
    }
    
    .ProcessValidation-card-text{
        font-family: 'GobCL_LightWeight';
        font-size: 16px;
    }
}