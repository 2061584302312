.Training-title{
    margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.Training-paragraph{
    text-align: center;
    font-family: 'GobCL_LightWeight';
    font-size: 20px;
}

.TematicsTraining-card{
    border: 0cap;
    margin: 10%;
}

.TematicsTraining-icoTitle{
    text-align: center;
    font-family: 'GobCL_BoldLight';
    font-size: 30px;
}

.TematicsTraining-icoImage{
    align-items: center;
}

.tab-custom{
    font-size: 20px;
    font-family: 'GobCL_LightWeight';
}

/* Estilos personalizados para las flechas del carrusel */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5); /* Añade un fondo semitransparente */
    border-radius: 50%; /* Hacer las flechas redondas */
    padding: 10px; /* Añade un padding para agrandar el área de la flecha */
    width: 40px;
    height: 40px;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Haz que cambie de color al pasar el ratón */
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%; /* Controla el tamaño del área de clic */
}


/* Media Query for Mobile */
@media (max-width: 768px) {
    .Training-title{
        margin-top: 1%;
        font-family: 'GobCL_BoldWeight';
        font-size: 20px;
        margin-bottom: 5px;
        text-align: center;
    }
    
    .Training-paragraph{
        text-align: center;
        font-family: 'GobCL_LightWeight';
        font-size: 16px;
    }
    
    .TematicsTraining-card{
        border: 0cap;
        margin: 10%;
    }
    
    .TematicsTraining-icoTitle{
        text-align: center;
        font-family: 'GobCL_BoldLight';
        font-size: 16px;
    }
    
    .TematicsTraining-icoImage{
        align-items: center;
    }
    
    .tab-custom{
        font-size: 16px;
        font-family: 'GobCL_LightWeight';
    }
}