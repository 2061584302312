.footer {
    background-color: #000;
    color: #fff;
    padding: 2rem 0;
}

.footer h5 {
    font-family: 'GobCL_BoldLight';
    margin-bottom: 1rem;
}

.footer p {
    font-family: 'GobCL_LightRegular';
    margin-bottom: 0.5rem;
}

.footer-link {
    color: #fff;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}
