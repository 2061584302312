.BannerContainer {
    position: relative;
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.BannerClip {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
}

.BannerOverlay {
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.btn-indap{
    font-family: 'GobCL_BoldWeight';
    font-size: 23px;
}

.BannerCard {
    background-color: rgba(128, 128, 128, 0.495);
    padding: 20px;
    width: 30%;
    border-radius: 10px;
    text-align: center;
}

.BannerTitle {
    font-family: 'GobCL_BoldWeight';
    color: white;
    font-size: 45px;
    margin-bottom: 30px;
}

.BannerParagraph {
    font-family: 'GobCL_LightWeight';
    color: white;
    font-size: 30px;

}

/* Mobile Design */
@media (max-width: 768px) {
    .Banner-Container {
        position: relative;
        width: 100%;
        height: 700px;
        overflow: auto;
    }    
    .BannerOverlay {
        position: absolute;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 700px;
    }
    .BannerClip {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
    }    
    .BannerCard {
        background-color: rgba(128, 128, 128, 0.495);
        padding: 20px;
        width: 80%;
        border-radius: 10px;
        text-align: center;
    }
    .BannerTitle {
        font-family: 'GobCL_BoldWeight';
        color: white;
        font-size: 20px;
        margin-bottom: 30px;
    }
    
    .BannerParagraph {
        font-family: 'GobCL_LightWeight';
        color: white;
        font-size: 16px;
    
    }
}