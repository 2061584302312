.homecrisis-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 1rem;
}

.homecrisis-title {
    font-family: 'GobCL_BoldWeight';
    margin-bottom: 1rem;
    font-size: 2rem;
}

.homecrisis-video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
}

.hoomecrisis-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

@media (max-width: 768px) {
    .homecrisis-container {
        position: relative;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
        padding: 2rem 1rem;
    }
    
    .homecrisis-title {
        font-family: 'GobCL_BoldWeight';
        margin-bottom: 2%;
        font-size: 25px;
    }
    
    .homecrisis-video-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
        overflow: hidden;
    }
    
    .hoomecrisis-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

}
