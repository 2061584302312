.embed-responsive-16by9 {
    padding-bottom: 56.25%; /* 16:9 ratio */
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.embed-responsive-16by9 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.carouselyoutube{
    height: 750px;

}

/* Mobile Design */
@media (max-width: 768px) {
    .carouselyoutube{
        height: 300px;
    
    }
}