.cardPosts{
    width: 100%;
}

.containerPosts{
    margin-top: 20px;
    margin-bottom: 20px;
}

.parrafopost{
    font-size: 25px;
}

.titlepost{
    font-size: 35px;
}

.postButton{
    margin-right: 10px;
}

.imgPost img {
    width: 100%;
    height: 400px; /* Ajusta esta altura según tus necesidades */
    object-fit: cover;
}
