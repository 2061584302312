/* Ejemplo de estilos adicionales */
.Capabilities2-cardtitle {
    font-family: 'GobCL_BoldLight';
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
}

.Capabilities2-cardtext {
    font-family: 'GobCL_LightWeight';
    text-align: justify;
    font-size: 20px;
}

.Capabilities2-title {
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
}


.Capabilities2-button{
    margin-left: 40%;
}

.Capabilities2-cardimg{
    height: 300px;
}

.Capabilities2component{
    margin-top: 60px;
}

.Capabilities2-cardtheme{
    height: 790px;
    box-shadow: 0px 0px 50px -10px rgba(0, 0, 0, 0.493);
}