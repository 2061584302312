.SpecificConvent2-container {
    margin-top: 2rem;
}

.SpecificConvent2-title {
    margin-top: 6%;
    font-family: 'GobCL_BoldWeight';
    font-size: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.SpecificConvent2-card {
    margin-bottom: 5%;
    background-color: #f8f9fa; /* Optional: Change background color if needed */
    -webkit-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    -moz-box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
    box-shadow: 8.5px 8.5px 16.5px -2px #9f9f9f;
}

.SmartfieldSpecificConvent2-img{
    height: auto;
}

.SmartfieldSpecificConvent2-arrow{
    margin-bottom: 65%;
    margin-top: 10%;
}

.SpecificConvent2-card-text{
    font-family: 'GobCL_LightWeight';
    font-size: 18px;
}