.more-documents-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .more-documents-container h2 {
    margin-bottom: 20px;
  }
  
  .more-documents-container .btn-success {
    font-size: 16px;
    padding: 10px 20px;
  }
  